import AboutUs from "./aboutUs";
import Boarding from "./boarding";
import CustomersReport from "./customersReport";
import Footer from "./footer";
import WhatWeDo from "./whatWeDo";


function App() {
  return (
    <div className="App">
      <Boarding />
      <AboutUs />
      <WhatWeDo />
      <CustomersReport />
      <Footer />
    </div>
  );
}

export default App;
