import { Col, Row } from "antd";
import React from "react";
import Fade from 'react-reveal/Fade';
import "./style.scss";

export default function AboutUs() {
  return (
    <div className="aboutUs">
      <Row gutter={[24,24]} style={{ width: "100%", height: "100%" }}>
        <Col xs={22} sm={22} md={22} style={{ width: "100%", height: "100%" }}>
          <Fade left>
          <Row style={{ width: "100%", minHeight:'540px',textAlign:'center' }} align="middle" justify="center">
            <Col span={20}>
              <h1 style={{  fontSize: "3rem" }}>About Us</h1>
              <p style={{  fontSize: "25px" }}>
                Eben Plastic Limited, we provide the professional engineering service for your convenient business in the plastics and rubber industries
              </p>
            </Col>
          </Row>
          </Fade>
        </Col>
      </Row>
    </div>
  );
}
