import React from "react";
import { Button, Col, Row } from "antd";
import Fade from 'react-reveal/Fade';
import Img from "./static/pngwing.png";
import Logo from "./static/logo.png"
import "./style.scss";

export default function Boarding() {
  return (
    <div className="boarding">
      <Row gutter={[24, 24]} style={{ width: "100%", height: "100%" ,padding:'10px'}}>
        <Col  xs={12}>
          <a href=".">
          <Fade left>
          <img src={Logo} alt="" style={{width:'50px',height:'50px'}}/>
          </Fade>
          </a>
        </Col>
        <Col  xs={12} style={{ width: "100%", height: "100%",textAlign:'right' }}>
          <Button>Home</Button>
          </Col>
      </Row>
      <Row gutter={[24, 24]} style={{ height: "100%", width: "100%" }}>
        <Col xs={22} sm={22} md={12} lg={14} style={{ paddingLeft: "50px" }}>
          <Row style={{ height: "100%", width: "100%" }} align="middle">
            <Col>
              <h1 style={{ color: "white", fontSize: "25px" }}>
                <strong>Hello There!!</strong>{" "}
              </h1>
              <h1 style={{ color: "white", fontSize: "25px" }}>
                Welcome, To{" "}
                <strong style={{ color: "white" }}>
                  Eben Plastics Limited
                </strong>{" "}
              </h1>
              <p style={{ color: "white", fontSize: "15px" }}>
                <strong>Plastic Bags Manufacturing</strong>{" "}
              </p>
            </Col>
          </Row>
        </Col>
        <Col xs={22} sm={22} md={12} lg={10}>
          <img src={Img} alt="" className="boarding__img" />
        </Col>
      </Row>
    </div>
  );
}
