import { Col, Row, Typography } from "antd";
import React from "react";
import Img1 from "./static/polythene.jpg";
import Img2 from "./static/rubber.png";
import Img3 from "./static/rubberSolid.gif";
import "./style.scss";

export default function WhatWeDo() {
  return (
    <div className="whatWeDo">
      <div className="body">
        <div style={{ textAlign: "center" }}>
          <Typography.Title style={{ color: "white" }}>
            What We Produce
          </Typography.Title>
        </div>
        <Row gutter={[32, 32]} style={{ paddingInline: "20px" }}>
          <Col xs={22} sm={22} md={12} lg={8} style={{ textAlign: "center" }}>
            <img src={Img1} alt="" style={{ width: "100%", height: "190px" }} />
            <p style={{ color: "white", fontSize: "20px" }}>
              <strong> Poly Bags</strong>
            </p>
          </Col>
          <Col xs={22} sm={22} md={12} lg={8} style={{ textAlign: "center" }}>
            <img src={Img2} alt="" style={{ width: "100%", height: "190px" }} />
            <p style={{ color: "white", fontSize: "20px" }}>
              <strong>HD and LD Sachet Bags</strong>{" "}
            </p>
          </Col>
          <Col xs={22} sm={22} md={12} lg={8} style={{ textAlign: "center" }}>
            <img src={Img3} alt="" style={{ width: "100%", height: "190px" }} />
            <p style={{ color: "white", fontSize: "20px" }}>
              <strong>Plastic Bags</strong>{" "}
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
}
