import { Col, Row, Typography } from "antd";
import React from "react";

export default function CustomersReport() {
  return (
    <div className="customer">
      <div style={{ textAlign: "center" }}>
        <Typography.Title style={{ color: "white" }}>
          What Our Customers Are Says
        </Typography.Title>
      </div>
      <br />
      <br />
      <Row gutter={[32, 32]} style={{ paddingInline: "20px" }}>
        <Col
          xs={22}
          sm={22}
          md={12}
          lg={8}
          style={{ textAlign: "center", color: "white", padding: "10px" }}
        >
          <p>
            <strong>Grace Addo</strong>{" "}
          </p>
          <br />
          <p>
            I can always count on Eben Plastic to produce a quality product in a
            very reasonable amount and for a great price. That is why I atways
            choose Eben Plastic.
          </p>
        </Col>
        <Col
          xs={22}
          sm={22}
          md={12}
          lg={8}
          style={{ textAlign: "center", color: "white", padding: "10px" }}
        >
          <p>
            <strong>Owusu</strong>{" "}
          </p>
          <br />
          <p>
            Eben Plastic is a first-class company that delivers on quality and
            consistency and is on time with deliveries! I can count on them
            forever,
          </p>
        </Col>
        <Col
          xs={22}
          sm={22}
          md={12}
          lg={8}
          style={{ textAlign: "center", color: "white", padding: "10px" }}
        >
          <p>
            <strong>Rebecca Twum</strong>{" "}
          </p>
          <br />
          <p>
            1 have been doing business with Eben Plastic for a number of years
            now, I highly recommend them to anyone in the industry as olten as I
            can, They are a hard working group of people with their 1st goal
            always being the customer, Quality products! with great prices
            followed up with outstanding customer service. They truly have
            played a major part in my business ventures
          </p>
        </Col>
      </Row>
    </div>
  );
}
