import { Button, Col, Divider, Input, Row, Space } from "antd";
import React from "react";
import {
  AiOutlineMail,
  AiOutlineInstagram,
  AiOutlineFacebook,
} from "react-icons/ai";
import Img1 from "./static/logo.png"
import "./style.scss";

export default function Footer() {
  return (
    <div className="footer">
      <br />
      <Row gutter={[32, 64]}>
        <Col xs={22} sm={22} md={12} lg={8}>
          {/* <h1 style={{ color: "white", fontSize: "25px" }}>
            <strong>Eben Plastics Logo</strong>
          </h1> */}
          <img src={Img1} alt="" style={{width:'130px',height:'103px'}}/>
          <br />
          <br />
          <p style={{ color: "white", fontSize: "15px" }}>
            We Provide Quality And Affordable Produces For Our Customers.
          </p>
        </Col>
        <Col xs={22} sm={22} md={12} lg={8}>
          <h1 style={{ color: "white", fontSize: "25px" }}>
            <strong>Eben Plastics Logo</strong>
          </h1>
          <br />
          <p style={{ color: "white", fontSize: "15px" }}>
            Accra - Ghana <br />
            Kasoa
          </p>
          <p style={{ color: "white", fontSize: "15px" }}>
            <strong>Phone:</strong> +233-257-910-005
          </p>
          <p style={{ color: "white", fontSize: "15px" }}>
            <strong>Email:</strong> humanresource@ebenplastic.com
          </p>
        </Col>
        <Col xs={22} sm={22} md={12} lg={8}>
          <h1 style={{ color: "white", fontSize: "25px" }}>
            <strong>Newsletter</strong>
          </h1>
          <br />
          <Input prefix={<AiOutlineMail />} />
          <br />
          <br />
          <Space>
            <Button shape="circle">
              <AiOutlineMail />
            </Button>
            <Button shape="circle">
              <AiOutlineInstagram />
            </Button>
            <Button shape="circle">
              <AiOutlineFacebook />
            </Button>
          </Space>
        </Col>
      </Row>
      <Divider
        type="horizontal"
        style={{ color: "white", background: "white" }}
      />
      <br />
      <div style={{ textAlign: "center" }}>
        <p style={{ color: "white", fontSize: "15px" }}>
          <strong>Copyright © 2022 Eben Plastics Limited</strong>
        </p>
      </div>
    </div>
  );
}
